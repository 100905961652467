import "bootstrap/js/dropdown";
import "bootstrap/js/collapse";
import AOS from "aos";
import "lazysizes";
import ScrollEffects from "./components/scrolling-effects";

(function init() {
    ScrollEffects.init();
    // Initialise animation library
    AOS.init({
        easing: "ease-in-sine",
        duration: 1000
    });
})();
