export default class ScrollEffects {
    static init() {
        $("#menu-toggle").on("click", () => {
            $(".react-interactive-signup").toggleClass("index");
        });

        $(".menu__link").on("click", () => {
            $(".navbar-collapse").removeClass("in");
        });
    }
}
